// @Author: Pratik Hyomba (hyomba.pratik@gmail.com)

$space-values: (0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10) !default;

// padding, margin and font-size shorthands
$space-prefixes: (
    p: padding,
    pt: padding-top,
    pr: padding-right,
    pb: padding-bottom,
    pl: padding-left,
    m: margin,
    mt: margin-top,
    mr: margin-right,
    mb: margin-bottom,
    ml: margin-left,
    fs: font-size,
    w: width,
    h: height,
) !default;

$space-prefixes-vertical: (
    pv: padding,
    mv: margin,
) !default;

$space-prefixes-horizontal: (
    ph: padding,
    mh: margin,
) !default;

// alignments
$alignmentValues: ("right", "left", "center");
$alignmentProperties: (text-align, float);

//font-weight
$fontWeightValues: (100, 200, 300, 400, 500, 600, 700, 800, 900, "unset");
$fontWeightProperties: (font-weight);

//text-transform
$textTransformValues: ("capitalize", "lowercase", "uppercase");
$textTransformProperties: (text-transform);

//text-decoration
$textDecorationValues: ("underline");
$textDecorationProperties: (text-decoration);

@mixin pratik-helpers() {
    //text-decoration
    @each $property in $textDecorationProperties {
        @each $value in $textDecorationValues {
            .#{"text"}-#{$value} {
                #{$property}: #{$value};
            }
        }
    }

    //letter-spacing
    @each $value in $space-values {
        .#{"letter-spacing"}-#{$value} {
            #{"letter-spacing"}: #{$value}#{"px"};
        }
    }

    //text-transform
    @each $property in $textTransformProperties {
        @each $value in $textTransformValues {
            .#{"text"}-#{$value} {
                #{$property}: #{$value};
            }
        }
    }

    //font-weight
    @each $property in $fontWeightProperties {
        @each $value in $fontWeightValues {
            .#{"font"}-#{$value} {
                #{$property}: #{$value};
            }
        }
    }

    // alignments
    @each $property in $alignmentProperties {
        @each $value in $alignmentValues {
            .#{$property}-#{$value} {
                #{$property}: #{$value};
            }
        }
    }

    //padding and margin
    @each $attr-short, $attr-long in $space-prefixes {
        @each $value in $space-values {
            .#{$attr-short}-#{$value}-#{"remx"} {
                #{$attr-long}: #{"0."}#{$value}#{"rem"} !important;
            }

            .#{$attr-short}-#{$value}-#{"rem"} {
                #{$attr-long}: #{$value}#{"rem"} !important;
            }

            .#{$attr-short}-#{"1"}-#{$value}-#{"rem"} {
                #{$attr-long}: #{"1."}#{$value}#{"rem"} !important;
            }
        }
    }

    // padding and margin vertical
    @each $attr-short, $attr-long in $space-prefixes-vertical {
        @each $value in $space-values {
            .#{$attr-short}-#{$value}-#{"remx"} {
                #{$attr-long}-top: #{"0."}#{$value}#{"rem"} !important;
                #{$attr-long}-bottom: #{"0."}#{$value}#{"rem"} !important;
            }

            .#{$attr-short}-#{$value}-#{"rem"} {
                #{$attr-long}-top: #{$value}#{"rem"} !important;
                #{$attr-long}-bottom: #{$value}#{"rem"} !important;
            }

            .#{$attr-short}-#{"1"}-#{$value}-#{"rem"} {
                #{$attr-long}-top: #{"1."}#{$value}#{"rem"} !important;
                #{$attr-long}-bottom: #{"1."}#{$value}#{"rem"} !important;
            }
        }
    }

    //padding and margin horizontal
    @each $attr-short, $attr-long in $space-prefixes-horizontal {
        @each $value in $space-values {
            .#{$attr-short}-#{$value}-#{"remx"} {
                #{$attr-long}-left: #{"0."}#{$value}#{"rem"} !important;
                #{$attr-long}-right: #{"0."}#{$value}#{"rem"} !important;
            }

            .#{$attr-short}-#{$value}-#{"rem"} {
                #{$attr-long}-left: #{$value}#{"rem"} !important;
                #{$attr-long}-right: #{$value}#{"rem"} !important;
            }

            .#{$attr-short}-#{"1"}-#{$value}-#{"rem"} {
                #{$attr-long}-left: #{"1."}#{$value}#{"rem"} !important;
                #{$attr-long}-right: #{"1."}#{$value}#{"rem"} !important;
            }
        }
    }
}

@include pratik-helpers();

//buttons
button[size="large"] {
    @extend .pv-4-remx;
    @extend .ph-2-rem;
    @extend .fs-1-1-rem;
}

//basic properties
* {
    outline: none;
}

img {
    max-width: 100%;
}

a,
[routerLink] {
    cursor: pointer;
}
