@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

@import './app/core/scss/helpers.scss';
// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@import '~@angular/material/theming';
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat-core();

@import '~@ng-select/ng-select/themes/material.theme.css';

$success: mat-color($mat-green, 700);
$alert: mat-color($mat-yellow, 700);
$error: mat-color($mat-red, 500);
$success-light: mat-color($mat-green, 200);
$alert-light: mat-color($mat-yellow, 200);
$error-light: mat-color($mat-red, 200);
$gray: mat-color($mat-gray, 500);

:root {
    --primary-color: #353a48;
    --success: #{$success};
    --success-light: #{$success-light};
    --alert: #{$alert};
    --alert-light: #{$alert-light};
    --error: #{$error};
    --error-light: #{$error-light};
    --gray: #{$gray};
}

$primary: (
    50: #ececee,
    100: #c5c6cb,
    200: #9ea1a9,
    300: #7d818c,
    400: #5c616f,
    500: #3c4252,
    600: #353a48,
    700: #2d323e,
    800: #262933,
    900: #1e2129,
    A100: #c5c6cb,
    A200: #9ea1a9,
    A400: #5c616f,
    A700: #2d323e,
    contrast: (
        50: $dark-primary-text,
        100: $dark-primary-text,
        200: $dark-primary-text,
        300: $light-primary-text,
        400: $light-primary-text,
        500: $light-primary-text,
        600: $light-primary-text,
        700: $light-primary-text,
        800: $light-primary-text,
        900: $light-primary-text,
        A100: $dark-primary-text,
        A200: $light-primary-text,
        A400: $light-primary-text,
        A700: $light-primary-text,
    ),
);

$accent: (
    50: #e8f4e9,
    100: #c7e3c8,
    200: #a1d0a3,
    300: #7bbd7e,
    400: #5fae63,
    500: #43a047,
    600: #3d9840,
    700: #348e37,
    800: #2c842f,
    900: #1e7320,
    A100: #b0ffb2,
    A200: #7dff80,
    A400: #4aff4e,
    A700: #30ff35,
    contrast: (
        50: $dark-primary-text,
        100: $dark-primary-text,
        200: $dark-primary-text,
        300: $dark-primary-text,
        400: $dark-primary-text,
        500: $light-primary-text,
        600: $light-primary-text,
        700: $light-primary-text,
        800: $light-primary-text,
        900: $light-primary-text,
        A100: $dark-primary-text,
        A200: $dark-primary-text,
        A400: $dark-primary-text,
        A700: $dark-primary-text,
    ),
);

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$pando-portal-v2-primary: mat-palette($primary);
$pando-portal-v2-accent: mat-palette($accent);

// The warn palette is optional (defaults to red).
$pando-portal-v2-warn: mat-palette($mat-red);

// Create the theme object (a Sass map containing all of the palettes).
$pando-portal-v2-theme: mat-light-theme(
    $pando-portal-v2-primary,
    $pando-portal-v2-accent,
    $pando-portal-v2-warn
);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include angular-material-theme($pando-portal-v2-theme);

/* You can add global styles to this file, and also import other style files */

html,
body {
    height: 100%;
}
body {
    margin: 0;
    font-family: Roboto, 'Helvetica Neue', sans-serif;
}

table {
    width: 100%;
}

a {
    text-decoration: none;
}

.mat-success {
    background-color: var(--color-success);
    &.mat-raised-button {
        color: var(--color-white);
    }
    &.mat-slide-toggle {
        background-color: var(--color-white);

        &.mat-checked {
            .mat-slide-toggle-thumb,
            .mat-slide-toggle-bar {
                background-color: var(--color-success);
            }
        }
    }
}

.mat-brown {
    background-color: var(--color-brown);
    &.mat-raised-button {
        color: var(--color-white);
        &[fill='outline'] {
            border: 2px solid var(--color-brown);
            background-color: var(--color-white);
            color: var(--color-brown);
        }
    }
}

.mat-light {
    background-color: var(--color-light);
}

.filter-option {
    & > .mat-pseudo-checkbox {
        display: none !important;
    }

    button {
        margin-right: 1rem;
    }
}

.text-warning {
    color: #f44336;
}

mat-menu.settings-menu {
    max-width: 400px !important;
    width: 400px !important;
}
